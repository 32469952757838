import React, { Component } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import BigImage from "./modal";

class Gallery extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      modalShow: false,
      image: null
    };
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false, image: null });

    return (
      <>
        <BigImage
          show={this.state.modalShow}
          onHide={modalClose}
          capture={this.state.image}
        />
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block"
              src={"https://taller70-img.s3.us-east-2.amazonaws.com/decks/0001.jpg"}
              alt=""
              onClick={() => this.setState({ modalShow: true, image: "https://taller70-img.s3.us-east-2.amazonaws.com/decks/0001.jpg" })}
            />
            <Carousel.Caption>
              <Link
                to={{
                  pathname: "/productos/",
                  hash: "decks"
                }}
                style={{ color: "white" }}
              >
                <h3>Decks</h3>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block"
              src={"https://taller70-img.s3.us-east-2.amazonaws.com/terra/0001.jpg"}
              alt=""
              onClick={() => this.setState({ modalShow: true, image: "https://taller70-img.s3.us-east-2.amazonaws.com/terra/0001.jpg" })}
            />
            <Carousel.Caption>
              <Link
                to={{
                  pathname: "/productos/",
                  hash: "terrazas"
                }}
                style={{ color: "white" }}
              >
                <h3>Terrazas</h3>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block"
              src={"https://taller70-img.s3.us-east-2.amazonaws.com/patio/0001.jpg"}
              alt=""
              onClick={() => this.setState({ modalShow: true, image: "https://taller70-img.s3.us-east-2.amazonaws.com/patio/0001.jpg" })}
            />
            <Carousel.Caption>
              <Link
                to={{
                  pathname: "/productos/",
                  hash: "patio"
                }}
                style={{ color: "white" }}
              >
                <h3>Casas Victorianas</h3>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block"
              src={"https://taller70-img.s3.us-east-2.amazonaws.com/decks/0002.JPG"}
              alt=""
              onClick={() => this.setState({ modalShow: true, image: "https://taller70-img.s3.us-east-2.amazonaws.com/decks/0002.JPG" })}
            />
            <Carousel.Caption>
              <Link
                to={{
                  pathname: "/productos/",
                  hash: "decks"
                }}
                style={{ color: "white" }}
              >
                <h3>Decks</h3>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block"
              src={"https://taller70-img.s3.us-east-2.amazonaws.com/patio/0007.jpg"}
              alt=""
              onClick={() => this.setState({ modalShow: true, image: "https://taller70-img.s3.us-east-2.amazonaws.com/patio/0007.jpg" })}
            />
            <Carousel.Caption>
              <Link
                to={{
                  pathname: "/productos/",
                  hash: "patio"
                }}
                style={{ color: "white" }}
              >
                <h3>Mesas</h3>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block"
              src={"https://taller70-img.s3.us-east-2.amazonaws.com/patio/0002.jpg"}
              alt=""
              onClick={() => this.setState({ modalShow: true, image: "https://taller70-img.s3.us-east-2.amazonaws.com/patio/0002.jpg" })}
            />
            <Carousel.Caption>
              <Link
                to={{
                  pathname: "/productos/",
                  hash: "patio"
                }}
                style={{ color: "white" }}
              >
                <h3>Playgrounds</h3>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block"
              src={"https://taller70-img.s3.us-east-2.amazonaws.com/patio/0003.png"}
              alt=""
              onClick={() => this.setState({ modalShow: true, image: "https://taller70-img.s3.us-east-2.amazonaws.com/patio/0003.png" })}
            />
            <Carousel.Caption>
              <Link
                to={{
                  pathname: "/productos/",
                  hash: "patio"
                }}
                style={{ color: "white" }}
              >
                <h3>Casas Victorianas</h3>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </>
    );
  }
}

export default Gallery;
