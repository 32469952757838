import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import f_logo from "../icons/f_logo_RGB-Black_58.png";
import i_logo from "../icons/glyph-logo_May2016.png";
import m_logo from "../icons/iconfinder_aiga_mail_134146.svg";
import p_logo from "../icons/iconfinder_phone_326545.svg";

class ContactModal extends Component {
  render() {
    return (
      <>
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Informacion de Contacto
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul id="social">
              <li>
                <a
                  href="https://www.facebook.com/pg/taller70pr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={f_logo} alt="" width="30" height="30" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/taller70pr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={i_logo} alt="" width="30" height="30" />
                </a>
              </li>
            </ul>
            <ul id="phone">
              <img id="clogo" src={p_logo} alt="" width="23" height="23" />
              <a href="tel:+17872333837">787-233-3837</a>
            </ul>
            <ul id="mail">
              <img id="clogo" src={m_logo} alt="" width="27" height="17" />
              <a href="mailto:tallersetenta@gmail.com">
                tallersetenta@gmail.com
              </a>
            </ul>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ContactModal;
