import React, { Component, Suspense } from "react";
import NavBar from "../components/navbar";
import Contact from "../components/contact";
import BigImage from "../components/modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const Deck = React.lazy(() => import("../components/deck"));
const Patio = React.lazy(() => import("../components/patio"));
const Terra = React.lazy(() => import("../components/terra"));
var AWS = require("aws-sdk");

class Productos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      image: null,
      patio: [],
      terra: [],
      decks: []
    };

    this.showImage = this.showImage.bind(this);
    this.fetchS3Objects = this.fetchS3Objects.bind(this);
  }

  showImage(pic) {
    this.setState({
      modalShow: true,
      image: pic
    });
  }

  fetchS3Objects() {
    let s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      Bucket: "taller70-img",
    });
    s3.listObjects(
      { Bucket: "taller70-img" },
      function (error, data) {
        if (error) {
          console.log("Failed to retrieve objects");
        } else {
          let decks = [];
          let patios = [];
          let terra = [];
          data.Contents.forEach((element) => {
            if (element.Size > 0) {
              let res = element.Key.split("/");
              switch (res[0]) {
                case "terra":
                  terra.push(res[1]);
                  break;
                case "patio":
                  patios.push(res[1]);
                  break;
                case "decks":
                  decks.push(res[1]);
                  break;
                default:
                  console.log(res);
              }
            }
          });
          this.setState({
            patio: patios,
            terra: terra,
            decks: decks,
          });
        }
      }.bind(this)
    );
  }

  componentDidMount() {
    let hash = window.location.hash;
    if (hash.length > 0) {
      document.getElementById(hash.slice(1, hash.length)).scrollIntoView();
    }

    this.fetchS3Objects();
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false, image: null });

    let patio = this.state.patio;
    let terra = this.state.terra;
    let decks = this.state.decks;

    return (
      <div id="productos">
        <span id="top">
          <NavBar />
          <BigImage
            show={this.state.modalShow}
            onHide={modalClose}
            capture={this.state.image}
          />
        </span>
        <Container>
          <hr className="style1" />
          <h2 id="title">Galeria de Productos</h2>
          <hr className="style1" />
          <Row className="justify-content-md-center">
            <Col md="4">
              <h5>
                • <a href="#patio">Patio</a>
              </h5>
            </Col>
            <Col md="4">
              <h5>
                • <a href="#terrazas">Terrazas</a>
              </h5>
            </Col>
            <Col md="4">
              <h5>
                • <a href="#decks">Decks</a>
              </h5>
            </Col>
          </Row>
          <h5>
            Para mas informacion o preguntas puede comunicarse con{" "}
            <a href="#end">nosotros.</a>
          </h5>
          <hr className="style1" />

          <span id="patio">
            <h2>Patio</h2>
            <Suspense fallback={<div>Loading...</div>}>
              <Patio showImage={this.showImage} list={patio} />
            </Suspense>
            <a href="#top">^ Navegar hacia arriba</a>
          </span>
          <hr className="style1" />

          <span id="terrazas">
            <h2>Terrazas</h2>
            <Suspense fallback={<div>Loading...</div>}>
              <Terra showImage={this.showImage} list={terra} />
            </Suspense>
            <a href="#top">^ Navegar hacia arriba</a>
          </span>
          <hr className="style1" />

          <span id="decks">
            <h2>Decks</h2>
            <Suspense fallback={<div>Loading...</div>}>
              <Deck showImage={this.showImage} list={decks} />
            </Suspense>

            <a href="#top">^ Navegar hacia arriba</a>
          </span>
          <hr className="style1" />
        </Container>
        <span id="end">
          <Contact />
        </span>
      </div>
    );
  }
}

export default Productos;
