import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

class BigImage extends Component {
  render() {
    return (
      <>
        <Modal
          {...this.props}
          dialogClassName="popup-view"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable
          size="lg"
        >
          <Modal.Body style={{ padding: 0 }}>
            <img
              id="modalImage"
              className="d-block"
              src={this.props.capture}
              alt=""
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default BigImage;
