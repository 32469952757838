import React, { Component } from "react";
import NavBar from "../components/navbar";
import Gallery from "../components/gallery";
import Contact from "../components/contact";

class Home extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <Gallery />
        <div id="about">
          <h1>Nuestros Servicios</h1>
          <div>
            <h4>
              Taller 70 es una compańía puertorriqueña que se especializa en
              construcciones de madera. Construimos terrazas, decks, pérgolas,
              gazebos, columpios, playgrounds, jardineras y cualquier otro
              proyecto similar.
            </h4>
            <div />
            <h4>
              Nos distinguimos por la calidad de nuestros trabajos. Usamos los
              mejores materiales del mercado y proveemos un servicio responsable
              y de calidad. Nuestro equipo experimentado le dará forma a sus
              ideas.
            </h4>
            <div />
            <h4>
              Llámanos al 787-233-3837 para una orientación y/o cotización libre
              de costo.
            </h4>
          </div>
        </div>
        <Contact />
      </div>
    );
  }
}

export default Home;
