import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import logo from "../icons/Logo-Taller70(1).png";
import ContactModal from "./contact_modal";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: window.location.pathname,
      modalShow: false
    };
  }

  render() {
    const location = this.state.location;
    let modalClose = () => this.setState({ modalShow: false, image: null });
    let navitems;
    if (location === "/") {
      navitems = (
        <div className="navbar-nav">
          <li className="nav-item nav-link active">
            Inicio<span className="sr-only">(current)</span>
          </li>
          <li className="nav-item">
            <Link to="/productos/" className="nav-link">
              Productos
            </Link>
          </li>
        </div>
      );
    } else if (location === "/productos/") {
      navitems = (
        <div className="navbar-nav">
          <Link to="/" className="nav-item nav-link">
            Inicio
          </Link>
          <li className="nav-item nav-link active">
            Productos<span className="sr-only">(current)</span>
          </li>
        </div>
      );
    }
    return (
      <>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <a className="navbar-brand" style={{ marginRight: "10px" }} href="/">
            <img src={logo} width="60" height="45" alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            {navitems}
          </div>
          <span className="navbar-text">
            <Button
              variant="light"
              onClick={() => this.setState({ modalShow: true })}
            >
              Contacto
            </Button>
          </span>
        </nav>
        <ContactModal show={this.state.modalShow} onHide={modalClose} />
      </>
    );
  }
}

export default NavBar;
